import angular, { IRootScopeService, IScope, cookies, idle } from 'angular';
import { IFlowinglyWindow } from './interfaces/flowingly.window';
import { IStateService } from 'angular-ui-router';
import { SharedAngular } from './@types/sharedAngular';
import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';

declare const window: IFlowinglyWindow;

runnerController.$inject = [
  '$rootScope',
  '$state',
  'sideMenuService',
  'authService',
  'flowListManager',
  'pubsubService',
  'userNotificationsApiService',
  'sessionService',
  'browserUtilsService',
  'Idle',
  '$cookies',
  'exagoApiService',
  'exagoJsApiResource',
  'tokenService',
  'permissionsService',
  'flowinglyConstants',
  'runnerFlowsFormatter',
  'APP_CONFIG',
  'gtmService',
  'scriptService',
  'appInsightsService',
  'flowApiService',
  '$scope',
  'webPushService'
];

function runnerController(
  $rootScope: IRootScopeService,
  $state: IStateService,
  sideMenuService: SideMenuService,
  authService: AuthService,
  flowListManager: FlowListManager,
  pubsubService: SharedAngular.PubSubService,
  userNotificationsApiService: SharedAngular.UserNotificationsApiService,
  sessionService: SharedAngular.SessionService,
  browserUtilsService: SharedAngular.BrowserUtilsService,
  Idle: idle.IIdleService,
  $cookies: cookies.ICookiesService,
  exagoApiService: ExagoApiService,
  exagoJsApiResource: SharedAngular.ExagoJsApiResource,
  tokenService: SharedAngular.TokenService,
  permissionsService: SharedAngular.PermissionsService,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  runnerFlowsFormatter: RunnerFlowsFormatterService,
  APP_CONFIG: SharedAngular.APP_CONFIG,
  gtmService: SharedAngular.GTMService,
  scriptService: SharedAngular.ScriptService,
  appInsightsService: SharedAngular.AppInsightsService,
  flowApiService: FlowApiService,
  $scope: IScope,
  webPushService: WebPushService
) {
  const rctrl = this;
  rctrl.logout = () => authService.logout(true);
  rctrl.pageHeader = angular.copy($rootScope.pageHeader);

  $rootScope.$on('$stateChangeStart', function (e, toState) {
    rctrl.pageHeader = angular.copy($rootScope.pageHeader);
    sethighlightState(toState);
  });

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams) {
    //for some reason $stateParams is empty when we need to use it in onWorkflowPublishedReceived,
    //therefore save the category here;
    rctrl.categoryId = toParams.categoryId;
  });

  // subscribe signalR events. These are used to respond to ANOTHER user completing an action
  // if the currently logged in user completes a step it will be handled by the service
  const runnerControllerKey = 'runner.controller';

  pubsubService.subscribe(
    'SIGNALR_RUNNER_START_FLOW',
    onFlowStarted,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_RUNNER_COMPLETE_STEP',
    updateFlowLists,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_RUNNER_REASSIGN_STEP',
    onRunnerFlowProgressed,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_RUNNER_WITHDRAW_FLOW',
    onRunnerFlowWithdrawn,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_WORKFLOW_PUBLISHED',
    onWorkflowPublishedReceived,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_WORKFLOW_UNPUBLISHED',
    onWorkflowUnPublishedReceived,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_USER_PROFILE_UPDATED',
    onUserProfileUpdatedReceived,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_ACTOR_DELETED',
    (event, params) => actorDeleted(params),
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_RUNNER_USER_TEAM_UPDATED',
    refreshFlows,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_RUNNER_NEW_FLOW_COMMENT_COUNT',
    onNewFlowCommentCountReceived,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_RUNNER_STEP_TASK_CANCELLED',
    onRunnerStepTaskCancelled,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_RUNNER_STEP_TASK_CREATED',
    onRunnerStepTaskCreated,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_RUNNER_STEP_TASK_UPDATED',
    onRunnerStepTaskUpdated,
    runnerControllerKey
  );
  pubsubService.subscribe(
    'SIGNALR_RUNNER_STEP_INTEGRATION_PROCESSING_UPDATED',
    onRunnerFlowProgressed,
    runnerControllerKey
  );

  $scope.$on('$destroy', () => {
    pubsubService.unsubscribeAll(runnerControllerKey);
  });

  // Handle timeout event
  $rootScope.$on('IdleStart', function () {
    if (tokenService.isAllApplicationIdle()) {
      console.log(
        `IdleStart all idle: ${localStorage.getItem(
          'ngIdle.expiry'
        )}, AllApplicationIdle: ${$cookies.get('ngIdle.expiry')}`
      );

      const loginRouteName = 'app.login';
      if ($state.current.name !== loginRouteName) {
        tokenService.setInLogoutProcess(true);
        authService.logout();
      }
    } else {
      console.log(
        `IdleStart: ${localStorage.getItem(
          'ngIdle.expiry'
        )}, AllApplicationIdle: ${$cookies.get('ngIdle.expiry')}`
      );
      tokenService.manualIdleCheck();
    }
  });

  $rootScope.$on('IdleInterrupt', function () {
    tokenService.setIdleExpiry();
  });

  initialise();

  function initialise() {
    if (
      !browserUtilsService.isMobileDevice() &&
      APP_CONFIG.enableExago &&
      permissionsService.currentUserHasPermission(
        flowinglyConstants.permissions.REPORT_BI_ACCESS
      )
    ) {
      const script =
        APP_CONFIG.exagoBaseUrl + 'WrScriptResource.axd?s=ExagoApi';
      scriptService
        .loadScript(script, 'text/javascript', 'utf-8')
        .then(loadExago);
    }

    createMenu();

    //collapses all the flows
    rctrl.expand = false;

    if (!browserUtilsService.isApp()) {
      Idle.watch();
      tokenService.setIdleExpiry();
    }

    console.log(
      `Idle watch: ${localStorage.getItem(
        'ngIdle.expiry'
      )}, AllApplicationIdle: ${localStorage.getItem(
        'ngIdle.expiry'
      )}, AllApplicationIdle.Mobile: ${localStorage.getItem(
        'ngIdle.expiry.mobile'
      )}`
    );

    if (APP_CONFIG.googleTagManagerId) {
      gtmService.loadGTM(APP_CONFIG.googleTagManagerId);
    }

    flowListManager.refreshFlowsTodo();
    webPushService.trySetupWebPush($scope);
  }

  function loadExago() {
    exagoApiService
      .getSessionKey()
      .then((data: { appUrl: string; apiKey: string }) => {
        if (!data) {
          console.warn("Can't create Exago API instance");
          return;
        }

        exagoJsApiResource.CreateInstance(data.appUrl, data.apiKey);
      });
  }
  function onNewFlowCommentCountReceived(event, data) {
    const msgdata = JSON.parse(data);

    flowListManager.updateFlowCommentCountInLists(
      msgdata.flowId,
      msgdata.commentCount
    );
  }

  function refreshFlows() {
    if (sessionService.isLoggedIn()) {
      flowListManager.refreshFlowInstanceLists();
      flowListManager.refreshReportFlows(false);
    }
  }

  function actorDeleted(jsonParams) {
    if (!sessionService.isLoggedIn()) {
      return;
    }

    const params = JSON.parse(jsonParams);
    if (params.replacementActorId === null) {
      return; // If no replacement then actor wasn't involved in any Flows
    }

    flowListManager.replaceFlowInstanceListsActor(
      params.actorName,
      params.replacementActorName
    );
  }

  function onFlowStarted(event, message) {
    onRunnerFlowProgressed(event, message);
  }

  function onRunnerFlowProgressed(event, message) {
    const data = JSON.parse(message);
    if (sessionService.isLoggedIn()) {
      if (
        signalrEventWasInitiatedByThisUser(data.userId) &&
        !data.isBulkStart
      ) {
        //dont handle signalR events initiated by this user - this is handled by FlowListManager
        return;
      }

      flowListManager.refreshFlowInstanceLists();
    }
  }

  function updateFlowLists(event, jsonParams) {
    const params = JSON.parse(jsonParams);
    // This awfullness is required as our API doesn't yet consistently return camelcase
    const flow = upperCaseObjectProperties(params.flow);
    const formattedFlow = runnerFlowsFormatter.formatFlow(flow);
    flowListManager.updateFlowInLists(formattedFlow);

    function upperCaseObjectProperties(obj) {
      if (typeof obj === 'string') {
        return obj;
      }
      return (<any>Object).fromEntries(
        (<any>Object)
          .entries(obj)
          .map(([k, v]) => [
            k.charAt(0).toUpperCase() + k.slice(1),
            transformValue(v)
          ])
      );

      function transformValue(value) {
        if (Array.isArray(value)) {
          value.forEach(
            (v, index, array) => (array[index] = upperCaseObjectProperties(v))
          );
        } else if (typeof value === 'object' && value) {
          return upperCaseObjectProperties(value);
        }
        return value;
      }
    }
  }

  function onRunnerStepTaskCancelled(event, message) {
    const data = JSON.parse(message);

    if (sessionService.isLoggedIn()) {
      if (signalrEventWasInitiatedByThisUser(data.userId)) {
        // dont handle signalR events initiated by this user
        return;
      }

      // Refresh the flow list so that any flows assigned to the
      // user to do or approve appear in their list.
      flowListManager.refreshFlowInstanceLists();

      pubsubService.publish('STEP_TASK_CANCELLED', data);
    }
  }

  function onRunnerStepTaskCreated(event, message) {
    const data = JSON.parse(message);

    if (sessionService.isLoggedIn()) {
      // Refresh the flow list so that any flows assigned to the
      // user to do or approve appear in their list.
      flowListManager.refreshFlowInstanceLists();

      const stepTaskId = data.stepTaskId;
      if (stepTaskId != null) {
        flowApiService.getStepTask(stepTaskId).then((stepTask) => {
          if (stepTask != null) {
            pubsubService.publish('STEP_TASK_CREATED', stepTask);
          }
        });
      }
    }
  }

  function onRunnerStepTaskUpdated(event, message) {
    const data = JSON.parse(message);

    if (sessionService.isLoggedIn()) {
      // Refresh the flow list so that any flows assigned to the
      // user to do or approve appear in their list.
      flowListManager.refreshFlowInstanceLists();

      const stepTaskId = data.stepTaskId;
      if (stepTaskId != null) {
        flowApiService.getStepTask(stepTaskId).then((stepTask) => {
          if (stepTask != null) {
            pubsubService.publish('STEP_TASK_UPDATED', stepTask);
          }
        });
      }
    }
  }

  function onRunnerFlowWithdrawn(event, message) {
    const data = JSON.parse(message);
    if (sessionService.isLoggedIn()) {
      if (signalrEventWasInitiatedByThisUser(data.userId)) {
        //dont handle signalR events initiated byt his user - this is handled by FlowListManager
        return;
      }
      // A card has been completed. For now, brute force load of all lists
      flowListManager.refreshFlowInstanceLists();
    }
  }

  function onWorkflowPublishedReceived(event, message) {
    ///
    /// This method handles a new workflow being published.
    /// It ensures that the new workflow is added to all lists.
    ///

    if (!sessionService.isLoggedIn()) {
      return;
    }

    flowListManager.refreshReportFlows(false);
  }

  function onWorkflowUnPublishedReceived(event, message) {
    ///
    /// This method handles a workflow being unpublished.
    /// It removes it from all lists.

    if (!sessionService.isLoggedIn()) {
      return;
    }

    flowListManager.refreshReportFlows(false);
  }

  function onUserProfileUpdatedReceived(event, message) {
    if (!sessionService.isLoggedIn()) {
      return;
    }

    let user = sessionService.getUser();
    sessionService.getUserDetails(user.email).then((resp) => {
      if (resp != undefined) {
        user = resp.data.dataModel;
        sessionService.clearUser();
        sessionService.setUser(user);
        pubsubService.publish(
          'CLIENT_USER_PROFILE_UPDATED',
          sessionService.getUser()
        );
      }
    });
  }

  function signalrEventWasInitiatedByThisUser(message) {
    // We only send down a payload (from SignalR) containing the initiatorId now
    const isInitiator = sessionService.getUser().id === message;
    return isInitiator;
  }

  function getFlowsTodoCount() {
    return flowListManager.countflowsTodo();
  }

  function getNotificationCount() {
    return userNotificationsApiService.notificationCount;
  }

  function sethighlightState(toState) {
    //Reports menu item should still be active when Report Page is loaded.
    //When toState is report, change toState name to "app.runner.reports" just for highlighting purpose.
    switch (toState.name) {
      case 'app.runner.flow':
        if ($rootScope.highlightState === undefined)
          $rootScope.highlightState = 'app.runner.flowsactive';
        break;
      case 'app.runner.flowsactive':
      case 'app.runner.flowstodo':
      case 'app.runner.flowsin':
        $rootScope.highlightState = toState.name;
        break;
      case 'app.runner.processmapview':
        $rootScope.highlightState = 'app.runner.processmap';
        break;
      case 'app.runner.report':
        $rootScope.highlightState = 'app.runner.reports';
        break;
      case 'app.runner.library':
        $rootScope.highlightState = 'app.runner.library';
        break;
      default: {
        if (
          toState.name.indexOf('app.runner.setup.') === 0 ||
          toState.name.indexOf('database') > -1
        )
          $rootScope.highlightState = 'app.runner.setup.users';
        else $rootScope.highlightState = undefined;
      }
    }
  }

  function showFlowsTodoCount() {
    return true;
  }

  function showNotificationCount() {
    return userNotificationsApiService.notificationCount > 0;
  }

  function createMenu() {
    const linkType = 'link';
    const linkWithActionType = 'link-with-action';
    const isMobileDevice = browserUtilsService.isMobileDevice();

    //Clearing existing menu whenever fresh createMenu is called. see FLOW-1194
    sideMenuService.clearMenu();

    sideMenuService.addmenuItem({
      name: 'Start',
      hovertext: 'Start Flows',
      mobilename: 'Start Flows',
      type: linkType,
      icon: 'fa-light fa-play',
      target: 'app.runner.flowsactive',
      permissions: [FlowinglyPermissions.FLOW_START]
    });

    sideMenuService.addmenuItem({
      name: 'To Do',
      hovertext: 'To Do',
      mobilename: 'To Do',
      type: 'link-with-count',
      icon: 'fa-light fa-list',
      target: 'app.runner.flowstodo',
      count: getFlowsTodoCount,
      show: showFlowsTodoCount,
      permissions: [FlowinglyPermissions.TODO_ACCESS]
    });

    sideMenuService.addmenuItem({
      name: 'Flows',
      hovertext: "Flows I'm In",
      mobilename: "Flows I'm In",
      type: linkType,
      icon: 'fa-light fa-merge',
      target: 'app.runner.flowsin'
    });

    if (!isMobileDevice) {
      if (APP_CONFIG.allowProcessMap && !APP_CONFIG.enableProcessMapsV2) {
        sideMenuService.addmenuItem({
          name: 'Maps',
          hovertext: 'Process Maps',
          type: linkType,
          icon: 'fa-light fa-file-alt',
          target: 'app.runner.processmap'
        });
      }

      if (APP_CONFIG.allowProcessMap && APP_CONFIG.enableProcessMapsV2) {
        sideMenuService.addmenuItem({
          name: 'Maps',
          hovertext: 'Process Maps',
          type: linkType,
          icon: 'fa-light fa-file-alt',
          target: 'app.runner.processmapv2'
        });
      }

      sideMenuService.addmenuItem({
        name: 'Reports',
        hovertext: 'Reports',
        type: linkType,
        icon: 'fa-light fa-table',
        target: 'app.runner.reports',
        permissions: [FlowinglyPermissions.REPORT_ACCESS]
      });

      if (APP_CONFIG.enableExago) {
        sideMenuService.addmenuItem({
          name: 'Dashboard',
          hovertext: 'Process Dashboard',
          type: linkType,
          icon: 'fa-light fa-chart-pie',
          target: 'app.runner.exagosingle',
          permissions: [FlowinglyPermissions.REPORT_BI_ACCESS]
        });

        sideMenuService.addmenuItem({
          name: 'Analytics',
          hovertext: 'Process Analytics',
          type: linkType,
          icon: 'fa-light fa-chart-bar',
          target: 'app.runner.exago',
          permissions: [FlowinglyPermissions.REPORT_BI_ACCESS]
        });
      }

      if (APP_CONFIG.enableTemplateLibrary && !isMobileDevice) {
        sideMenuService.addmenuItem({
          name: 'Templates',
          hovertext: 'Template Library',
          mobilename: 'Template Library',
          type: 'link-with-action-count',
          icon: 'fa-light fa-shapes',
          target: 'app.runner.templates.library',
          count: () => '!',
          show: () => true,
          action: () => {
            $state.go('app.runner.templates.library');
            appInsightsService.trackPageView('template-library', {
              from: 'sidemenu'
            });
          }
        });
      }

      sideMenuService.addmenuItem({
        name: 'Library',
        hovertext: 'Library',
        type: linkType,
        icon: 'fa-light fa-cubes',
        target: 'app.runner.library.workflows',
        permissions: ['library.access']
      });

      if (
        APP_CONFIG.enableProcessApprovals ||
        APP_CONFIG.showGovernanceFlowModelsWithReviewDue
      ) {
        sideMenuService.addmenuItem({
          name: 'Governance',
          hovertext: 'Governance',
          type: linkType,
          icon: 'fa-light fa-building-columns',
          target: 'app.runner.governance',
          show: true,
          permissions: [flowinglyConstants.permissions.GOVERNANCE_ACCESS]
        });
      }

      let targetState = null;

      if (
        permissionsService.currentUserHasPermission(
          flowinglyConstants.permissions.SETUP_USER_ACCESS
        )
      ) {
        targetState = 'app.runner.setup.users';
      } else if (
        permissionsService.currentUserHasPermission(
          flowinglyConstants.permissions.SETUP_DATABASE_ACCESS
        )
      ) {
        targetState = 'app.runner.setup.databases';
      } else if (
        permissionsService.currentUserHasPermission(
          flowinglyConstants.permissions.SETUP_TEAM_ACCESS
        )
      ) {
        targetState = 'app.runner.setup.teams';
      } else if (
        permissionsService.currentUserHasPermission(
          flowinglyConstants.permissions.SETUP_CATEGORY_ACCESS
        )
      ) {
        targetState = 'app.runner.setup.categories';
      }

      if (targetState !== null) {
        sideMenuService.addmenuItem({
          name: 'Setup',
          hovertext: 'Setup',
          type: linkType,
          icon: 'fa-light fa-gear',
          target: targetState
        });
      }
    }

    if (isMobileDevice) {
      userNotificationsApiService.getNotificationCount().then(() => {
        sideMenuService.addmenuItem({
          name: 'My Account',
          type: 'label'
        });
        sideMenuService.addmenuItem({
          name: 'Notifications',
          type: 'link-with-count',
          icon: 'fa-light fa-bell',
          target: 'app.runner.notifications',
          count: getNotificationCount,
          show: showNotificationCount
        });
        if (
          APP_CONFIG.enableWebPushNotificationsMobile &&
          browserUtilsService.isMobileWebApp()
        ) {
          sideMenuService.addmenuItem({
            name: 'Settings',
            hovertext: 'Settings',
            mobilename: 'Settings',
            type: linkType,
            icon: 'fa-light fa-gear',
            target: 'app.runner.settings'
          });
        }
        const user = sessionService.getUser();
        sideMenuService.addmenuItem({
          name: `Sign out ${user.fullName}`,
          type: linkWithActionType,
          icon: 'fa-light fa-sign-out-alt',
          action: () => {
            webPushService
              .removeUsersLocalSubscription()
              .finally(() => authService.logout(true));
          }
        });
      });
    }
  }
}

angular
  .module('flowingly.runner')
  .controller('runnerController', runnerController);
