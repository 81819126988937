import IRoleDetail from '@Shared.Angular/@types/core/contracts/queryModel/authorisation/roleDetail';
import IGroupDetail from '@Shared.Angular/@types/core/contracts/queryModel/group/groupDetail';
import IUserDetail from '@Shared.Angular/@types/core/contracts/queryModel/user/userDetail';
import { Guid } from '@Shared.Angular/@types/guid';
import LoginSettings from '@Shared.Angular/@types/loginSettings';
import angular, { IHttpPromiseCallbackArg, IQService } from 'angular';

/**
 * @ngdoc service
 * @name sessionService
 * @module flowingly.runner.services
 *
 * @description Service responsible for persisting user information using angular-storage
 *
 * ## Notes
    Note that store is from the angular-storage library (which is part of auth0)
    Uses localStorage or sessionStorage by default
 *
 * ###API
 * * format - format the server response into something the client side can consume.
 *            sorts the flows into date due buckets.
 *
*/
export interface ILocalUserDetail {
  ver: number;
  id: Guid;
  fullName: string;
  businessId: Guid;
  businessName: string;
  businessIndustry: string;
  businessPhoneNumber: string;
  isOwner: boolean;
  businessCreatedDate: number;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  jobTitle: string;
  email: string;
  roles: IRoleDetail[];
  managerName: string;
  managerUserId?: Guid;
  teams: IGroupDetail[];
  numberOfUsersManaged: number;
  inDelegationMode: boolean;
  delegateApprovalUserFullName: string;
  delegateApprovalUserId?: Guid;
  delegateStepUserFullName: string;
  delegateStepUserId?: Guid;
}

// Service responsible for persisting user information using angular-storage

angular.module('flowingly.services').factory('sessionService', sessionService);

sessionService.$inject = [
  '$location',
  '$rootScope',
  '$state',
  '$http',
  'APP_CONFIG',
  'tokenService',
  'store',
  'flowinglyConstants',
  '$q',
  '$timeout',
  'authLoggingApiService'
];

function sessionService(
  $location,
  $rootScope,
  $state,
  $http: angular.IHttpService,
  APP_CONFIG,
  tokenService,
  store,
  flowinglyConstants,
  $q: IQService,
  $timeout,
  authLoggingApiService
) {
  // We are using a different namespace for Development, Staging and Production (see web.config)
  const nameSpace = 'flowingly.' + APP_CONFIG.flowinglyEnvironment;
  const profileStoreName = nameSpace + '.profile';
  const userStoreName = nameSpace + '.user';
  const userPermissions = nameSpace + '.user.permissions';
  const signalRStoreName = nameSpace + '.signalr';
  const singleSignOnStorageName = nameSpace + '.isSSo';

  let _user: ILocalUserDetail = undefined; //Returned from the flowingly AspNetUsers table
  let _profile = store.get(profileStoreName); //Returned from auth0.com FlowinglyDb
  let _isIniitialLogin = undefined;
  let _signalR = store.get(signalRStoreName);
  const _currentUserVer = 7; // Increment the user ver here when changing the user settings for local storage
  store.set(singleSignOnStorageName, false);
  getUser();

  const service = {
    clear: clear,
    clearLoginFlag: clearLoginFlag,
    checkIfUserDeleted: checkIfUserDeleted,
    formatUserForLocalStorage: formatUserForLocalStorage,
    getUser: getUser,
    isLoggedIn: isLoggedIn,
    setInitialLogin: setInitialLogin,
    isInitialLogin: isInitialLogin,
    getProfile: getProfile,
    getPermissions: getPermissions,
    setProfile: setProfile,
    setuserAuthenticated: setuserAuthenticated,
    setUser: setUser,
    clearUser: clearUser,
    getSignalR: getSignalR,
    getUserDetails: getUserDetails,
    getSetting: getSetting,
    getSettings: getSettings,
    getToken: getToken,
    isOldUserVersion: isOldUserVersion,
    getUserVersion: getUserVersion,
    isFlowModelAdmin: isFlowModelAdmin,
    setIsSso: setIsSso,
    isSso: isSso,
    getUserFromStore: getUserFromStore,
    onAuthenticated: onAuthenticated,
    isAuthenticated: isAuthenticated,
    isAuthenticationInFlight: isAuthenticationInFlight,
    getLoginSettings: getLoginSettings,
    inTenantBusiness: inTenantBusiness,
    getBusinessName: getBusinessName,
    getBusinessId: getBusinessId,
    getSessionState: getSessionState,
    setSessionState: setSessionState,
    acquireAndStoreUser: acquireAndStoreUser,
    rehome: rehome
  };

  return service;

  //////////// Public API Methods

  function setIsSso(isSso) {
    authLoggingApiService.log(
      `sessionService.ts - setIsSso called with value ${isSso}`
    );
    store.set(singleSignOnStorageName, isSso);
  }

  function isSso() {
    return store.get(singleSignOnStorageName);
  }

  function isOldUserVersion() {
    if (
      _user !== undefined &&
      (_user.ver === undefined || _user.ver < _currentUserVer)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function getUserVersion() {
    return _currentUserVer;
  }

  function getToken() {
    return tokenService.getToken();
  }

  function getUserDetails(
    email,
    token?
  ): angular.IPromise<IHttpPromiseCallbackArg<IResponseData<IUserDetail>>> {
    const apiEndpoint = token ? 'getUserByToken' : 'getUser';
    const httpActionType = token ? 'get' : 'post';

    return $http[httpActionType]<IResponseData<IUserDetail>>(
      `${APP_CONFIG.apiBaseUrl}account/${apiEndpoint}`,
      { email: email }
    )
      .then(function (response) {
        // kick deleted user out
        if (checkIfUserDeleted(response.data)) {
          return undefined;
        }

        return response;
      })
      .catch(function onError() {
        // Handle error
        return undefined;
      });
  }

  function getSettings(noDefault: string[] = []) {
    return $http
      .get<IResponseDataPascalCase>(
        `${APP_CONFIG.apiBaseUrl}account/settings`,
        {
          params: { nodefault: noDefault }
        }
      )
      .then((response) => {
        return response.data && response.data.Success
          ? response.data.DataModel
          : {};
      })
      .catch(function onError() {
        return {};
      });
  }

  function getSetting(settingName) {
    return $http
      .get<IResponseDataPascalCase>(
        `${APP_CONFIG.apiBaseUrl}account/settings/business/${settingName}`
      )
      .then((response) => {
        return response.data && response.data.Success
          ? response.data.DataModel
          : undefined;
      })
      .catch(function onError() {
        return undefined;
      });
  }

  // Note we must only use this before saving to local storage as this is also where we now set the version
  // number for the user object
  function formatUserForLocalStorage(userDetail: IUserDetail) {
    return {
      ver: _currentUserVer, // We now change the version number every time we change these settings
      // That will force the user details to be re-retrived from server side
      // and saved to local storage and service variables
      id: userDetail.id,
      fullName: userDetail.fullName, // But we do need the fullname to be set
      businessId: userDetail.businessId,
      businessName: userDetail.businessName,
      businessIndustry: userDetail.businessIndustry,
      businessPhoneNumber: userDetail.businessPhoneNumber,
      isOwner: userDetail.isOwner,
      // For some resaon the date is being parsed with an extra 000, which leads to invalid date in Intercom
      // so for now, remobing them by dividing by 1000
      businessCreatedDate: Math.floor(
        Date.parse(userDetail.businessCreatedDate) / 1000
      ),
      firstname: userDetail.firstName,
      lastname: userDetail.lastName,
      phoneNumber: userDetail.phoneNumber,
      jobTitle: userDetail.jobTitle,
      email: userDetail.email,
      roles: userDetail.roles,
      managerName: userDetail.managerFullName,
      managerUserId: userDetail.managerUserId,
      teams: userDetail.groups,
      numberOfUsersManaged: userDetail.numberOfUsersManaged,
      inDelegationMode: userDetail.inDelegationMode,
      delegateApprovalUserFullName: userDetail.delegateApprovalUserFullName,
      delegateApprovalUserId: userDetail.delegateApprovalUserId,
      delegateStepUserFullName: userDetail.delegateStepUserFullName,
      delegateStepUserId: userDetail.delegateStepUserId
    } as ILocalUserDetail;
  }

  function getUserFromStore() {
    return store.get(userStoreName);
  }

  function getUser() {
    handleUnauthorisedAccess();

    if (_user === undefined) {
      const userObject = getUserFromStore();
      if (userObject == undefined) {
        return undefined;
      }
      _user = userObject;
    }

    if (_user !== undefined && (_user.ver === undefined || _user.ver < 6)) {
      // one off clear local storage and back to login page
      clear();
      authLoggingApiService.log(
        'sessionService.ts - getUser() _user is not undefined and _user.version is undefined or <6. About to be re-di-r-ected to app.login'
      );
      $state.go('app.login');
      return undefined;
    }

    if (isOldUserVersion()) {
      _user.ver = _currentUserVer; // Need to do this so we dont repeatedly get the user details until it has happened
      getUserDetails(_user.email).then(function (response) {
        if (response === undefined) {
          _user.ver = 0; //Invalid
        } else {
          const res = response.data;
          if (res.success === true) {
            const userDetails = formatUserForLocalStorage(res.dataModel);
            setUser(userDetails);
            $rootScope.user = userDetails;
            _user = userDetails;
            return _user;
          } else _user.ver = 0; //Invalid
        }
        return _user;
      });
    }
    return _user;
  }

  //The user is not fully logged in until we have the _user details retrieved
  //from the API
  function isLoggedIn() {
    handleUnauthorisedAccess();
    if (_user) {
      return true;
    } else {
      return false;
    }
  }

  function getSignalR() {
    _signalR = store.get(signalRStoreName);
    return _signalR;
  }

  function setUser(user: ILocalUserDetail) {
    _user = user;
    _user.ver = _currentUserVer;
    store.set(userStoreName, user);
  }

  function setuserAuthenticated() {
    $rootScope.isAuthenticated = true;
  }

  function isInitialLogin() {
    return _isIniitialLogin;
  }

  function setInitialLogin() {
    _isIniitialLogin = true;
  }

  // Ths is the user profile returned from our Auth0 Identity database (auth0.com - flowinglyDb)
  // TODO: we can use it to access basic user info including email and avatar (in particular)
  // At present we also have flowingly.user which is the more complete user info structure returned
  // from our Flowingly AspNetUsers table

  function setProfile(profile) {
    _profile = profile;
    store.set(profileStoreName, _profile);
  }

  function getProfile() {
    handleUnauthorisedAccess();
    _profile = store.get(profileStoreName);
    return _profile;
  }

  function getPermissions() {
    handleUnauthorisedAccess();
    return tokenService.getPermissions();
  }

  function clear() {
    authLoggingApiService.log('sessionService.ts - clear() was called.');
    tokenService.clearToken();
    store.remove(profileStoreName);
    store.remove(userStoreName);
    store.remove(userPermissions);
    _user = undefined;
    _profile = undefined;
  }

  function clearLoginFlag() {
    _isIniitialLogin = false;
  }

  function clearUser() {
    store.remove(userStoreName);
    _user = undefined;
  }

  function handleUnauthorisedAccess() {
    if (tokenService.wasUnauthorisedAccess()) {
      tokenService.setUnauthorisedAccess(false);
      clear();
    }
  }

  function checkIfUserDeleted(data) {
    const dataModel = data.dataModel || data.DataModel;
    const deleted = dataModel.delete || dataModel.Delete;

    if (deleted === true) {
      // kick deleted user out
      clear();
      authLoggingApiService.log(
        'sessionService.ts - checkIfUserDeleted() was called and deleted was true. About to be re-dir-ected to app.login'
      );
      $state.go('app.login');
    }

    return deleted;
  }

  function inTenantBusiness() {
    const tenant = tokenService.getTenant();
    return (
      tenant &&
      _user &&
      tenant.id.toLowerCase() !== _user.businessId.toLowerCase()
    );
  }

  function getBusinessName() {
    const tenantName = tokenService.getTenant()?.name;
    if (tenantName) {
      return tenantName;
    }
    return getUser()?.businessName;
  }

  function getBusinessId() {
    const tenantId = tokenService.getTenant()?.id;
    if (tenantId) {
      return tenantId;
    }
    return getUser()?.businessId;
  }

  // Avoid checking these roles and check permissions instead

  function isFlowModelAdmin() {
    return _user.roles.some(
      (role) =>
        role.name === flowinglyConstants.flowinglyRoles.FLOW_MODEL_ADMINISTRATOR
    );
  }

  /**
   * Use this function when you need to execute as soon as possible but also
   * need to be authenticated (example: front end level redirects).
   *
   * @returns {Promise} resolves to the authenticated user
   */
  function onAuthenticated() {
    return pollForLoginToken();
  }

  function isAuthenticated() {
    const hasToken = tokenService.getToken();
    const hasUser = service.getUserFromStore();
    return hasToken && hasUser;
  }

  /**
   *  NOTE: as of 7/03/2019 - when the url has an access_token is not logged in yet.
   *  If you try to redirect in this step, you will be kicked out.
   */
  function isAuthenticationInFlight() {
    return /access_token=/.test($location.$$hash);
  }

  /*
   * There is alot of frontend authentication mumbo jumbo things that happen
   * when an authentication is in flight. But we are totally unsure of when
   * or how it happens. I've literally tried chaining all the promises on this file
   * But i cannot, for the life of me, find what the exact conditions are
   * for being authentticated. On top of this hot mess, the mumbo jumbo has race conditions
   * and since the prod servers might be slow, so the only choice we have is to poll.
   *
   *                                                           - Cassey
   *
   * @returns {Promise} resolves to the authenticated user
   */
  function pollForLoginToken(deferred = $q.defer()) {
    // not these are not the _exact_ conditions for being authenticated
    // but we know that by the time this is populated, we are authenticated

    if (isAuthenticated()) {
      deferred.resolve(service.getUserFromStore());
    } else {
      $timeout(() => {
        pollForLoginToken(deferred);
      }, 100); // a poll every 100ms
    }
    return deferred.promise;
  }

  /**
   * Method to get settings before user login like welcome text, logoUrl etc
   */
  async function getLoginSettings(domainName: string) {
    try {
      const settings = await $http.get<LoginSettings>(
        `${APP_CONFIG.apiBaseUrl}settings/login?domainname=${domainName}`
      );

      return settings.data;
    } catch (e) {
      /* empty */
    }
  }

  function getSessionState(stateName) {
    return store.get(stateName);
  }

  function setSessionState(stateName, value) {
    store.set(stateName, value);
  }

  function acquireAndStoreUser(token) {
    tokenService.setToken(token, true);
    const email = null;
    return getUserDetails(email, token).then(function (response) {
      if (response === undefined) {
        $state.go('app.login'); //Invalid
      } else {
        _user = formatUserForLocalStorage(response.data.dataModel);
        store.set(userStoreName, _user);
      }
    });
  }

  function rehome(home: string) {
    const currentUrl = new URL(window.location.toString());
    const homeUrl = new URL(home);
    if (
      currentUrl.protocol !== homeUrl.protocol ||
      currentUrl.hostname !== homeUrl.hostname ||
      currentUrl.port !== homeUrl.port
    ) {
      const token = getToken();
      if (token && token !== 'null') {
        const rehomeUrl = currentUrl;
        rehomeUrl.protocol = homeUrl.protocol;
        rehomeUrl.hostname = homeUrl.hostname;
        rehomeUrl.port = homeUrl.port;
        const rehomeParams = rehomeUrl.searchParams;
        rehomeParams.delete('token');
        rehomeParams.append('token', token);
        rehomeParams.delete('profile');
        const profile = getProfile();
        rehomeParams.append('profile', profile);
        window.location.href = rehomeUrl.href;
      }
    }
  }
}

export type SessionServiceType = ReturnType<typeof sessionService>;
