import IFlowModelCommandData from '@Shared.Angular/@types/core/contracts/commands/modeler/flowModelCommandData';
import IFlowModelSchemaVersion from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/flowModelSchemaVersion';
import IProcessMapNestDetails from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/processMapNestDetails';
import IProcessMapNodeElement from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/processMapNodeElement';
import IModelerWorkflowDetail from '@Shared.Angular/@types/core/contracts/queryModel/modeler/modelerWorkflowDetail';
import { Guid } from '@Shared.Angular/@types/guid';
import { Services } from '@Shared.Angular/@types/services';
import { IFlowModelSchema, TimeCost } from '@Shared.Angular/@types/workflow';
import angular, { IHttpService, IQService } from 'angular';
import { StepTypeName } from './flowingly.constants';

class WorkflowApiService {
  static $inject = [
    '$http',
    'APP_CONFIG',
    '$q',
    'validationService',
    'flowinglyConstants'
  ];
  public static stepTypes = [];
  constructor(
    private $http: IHttpService,
    private APP_CONFIG: Services.APP_CONFIG,
    private $q: IQService,
    private validationService: Services.ValidationService,
    private flowinglyConstants: Services.FlowinglyConstants
  ) {}

  getFlowModel(workflowId) {
    return this.$http
      .get<IModelerWorkflowDetail>(
        this.APP_CONFIG.apiBaseUrl + 'modeler/findWorkflow/' + workflowId
      )
      .then((response) => {
        return response.data;
      });
  }

  getProcessMap(flowModelId: Guid, noSpinner = false) {
    return this.$http
      .get<IProcessMapNestDetails>(
        `${this.APP_CONFIG.apiBaseUrl}modeler/getProcessMap/${flowModelId}`,
        { noSpinner: noSpinner }
      )
      .then((response) => response.data);
  }

  getProcessMapbySchemaId(
    schemaId: string,
    noSpinner: boolean,
    hideFlowModelOpenButton = false
  ) {
    return hideFlowModelOpenButton
      ? this.$http
          .get<IProcessMapNestDetails>(
            `${this.APP_CONFIG.apiBaseUrl}map/schemas/${schemaId}`
          )
          .then((response) => response.data)
      : this.$http
          .get<IProcessMapNestDetails>(
            `${this.APP_CONFIG.apiBaseUrl}modeler/getProcessMapbySchemaId/${schemaId}`,
            { noSpinner: noSpinner }
          )
          .then((response) => response.data);
  }

  getProcessMapNodeElement(nodeId, noSpinner, hideFlowModelOpenButton = false) {
    return hideFlowModelOpenButton
      ? this.$http
          .get<IProcessMapNodeElement[]>(
            `${this.APP_CONFIG.apiBaseUrl}map/nodeElements/${nodeId}`,
            { noSpinner: noSpinner }
          )
          .then((response) => response.data)
      : this.$http
          .get<IProcessMapNodeElement[]>(
            `${this.APP_CONFIG.apiBaseUrl}modeler/getProcessMapNodeElement/${nodeId}`,
            { noSpinner: noSpinner }
          )
          .then((response) => response.data);
  }

  getSchemaByVersion(flowModelSchemaId) {
    ///
    /// Get FlowModel Schema by given version
    ///
    return this.$http.get<IFlowModelSchema>(
      this.APP_CONFIG.apiBaseUrl + 'modeler/schemaVersion/' + flowModelSchemaId
    );
  }

  getTimeCostTotal(schemaId) {
    return this.$http.get<TimeCost>(
      `${this.APP_CONFIG.apiBaseUrl}modeler/flowModelSchemas/${schemaId}/timeCost`
    );
  }

  getFlowModelSchemaVersions(workflowId) {
    return this.$http.get<IFlowModelSchemaVersion[]>(
      this.APP_CONFIG.apiBaseUrl + 'modeler/schemaVersions/' + workflowId
    );
  }

  getPublishApprovalFlowModelSchemaVersions(flowModelId) {
    return this.$http.get<IFlowModelSchemaVersion[]>(
      this.APP_CONFIG.apiBaseUrl +
        'modeler/publishApprovalsSchemaVersions/' +
        flowModelId
    );
  }

  getFlowModelSchemas(flowModelSchemasIds) {
    return this.$http
      .post(
        this.APP_CONFIG.apiBaseUrl + 'modeler/getFlowModelSchemas',
        flowModelSchemasIds
      )
      .then((response) => {
        return response.data;
      });
  }

  getStepTypes() {
    if (WorkflowApiService.stepTypes.length > 0) {
      return this.$q.when(WorkflowApiService.stepTypes);
    }
    return this.$http
      .get<IResponseDataPascalCase<[{ Key: number; Value: string }]>>(
        this.APP_CONFIG.apiBaseUrl + 'modeler/getStepTypes'
      )
      .then((response) => {
        const stepTypes = response.data.DataModel;
        // public form is not a valid step type, rather a front-end implementation
        stepTypes.push({
          Key: this.flowinglyConstants.stepType.PUBLIC_FORM,
          Value: this.flowinglyConstants.stepTypeName.PUBLIC_FORM
        });

        for (const stepType of stepTypes) {
          if (stepType.Value === 'ParallelApproval')
            stepType.Value = StepTypeName.PARALLEL_APPROVAL;
          else if (stepType.Value === 'SequentialApproval')
            stepType.Value = StepTypeName.SEQUENTIAL_APPROVAL;
          else if (stepType.Value === 'CustomEmail')
            stepType.Value = StepTypeName.CUSTOM_EMAIL;
          else if (stepType.Value === 'ExternalUserTask') {
            stepType.Value = StepTypeName.EXTERNAL_USER_TASK;
          }
        }

        WorkflowApiService.stepTypes = stepTypes;

        return stepTypes;
      });
  }

  getPublishApprovalFlowModel(flowModelId: Guid) {
    return this.$http
      .get<IModelerWorkflowDetail>(
        this.APP_CONFIG.apiBaseUrl + 'modeler/publishApprovals/' + flowModelId
      )
      .then((response) => {
        return response.data;
      });
  }

  checkFlowModelNameExists(businessId, name) {
    ///
    /// Check to see if we have a unique flow model name or not
    ///
    // Encode the name - it will be decoded in the ModelerController on server side.
    const url = this.APP_CONFIG.apiBaseUrl + 'modeler/queryUniqueName';
    return this.$http
      .get(url, {
        params: {
          businessId: businessId,
          name: this.validationService.sanitizeString(name)
        }
      })
      .then((response) => {
        return response.data.DataModel !== true; // Will be true if it is a unique name
      });
  }

  addWorkflow(workflow: IFlowModelCommandData) {
    return this.$http.put<IApiResponseSummary>(
      this.APP_CONFIG.apiBaseUrl + 'modeler/workflow',
      workflow
    );
  }

  cloneWorkflow(workflow) {
    ///
    /// create a clone of the supplied workflow
    ///
    return this.$http.post(
      this.APP_CONFIG.apiBaseUrl + 'modeler/workflow/clone',
      workflow
    );
  }

  updateWorkflow(workflow) {
    ///
    /// save supplied workflow
    ///
    return this.$http.post(
      this.APP_CONFIG.apiBaseUrl + 'modeler/workflow',
      workflow
    );
  }

  saveWorkflowDialogDetails(workflow) {
    ///
    /// save supplied workflow (name, description, and customplaceholder only)
    ///
    const details = JSON.parse(JSON.stringify(workflow));
    if (details.ProcessDetail && details.ProcessDetail.ProcessReviewDate) {
      details.ProcessDetail.ProcessReviewDate = null;
    }
    return this.$http.post(
      this.APP_CONFIG.apiBaseUrl + 'modeler/saveWorkflowDetails',
      details
    );
  }

  validateSchema(schema) {
    ///
    /// validate supplied schema
    ///
    return this.$http.post(
      this.APP_CONFIG.apiBaseUrl + 'modeler/validateschema',
      {
        Schema: schema
      }
    );
  }

  sendTestIntegrationMessage(integrationTestMessage) {
    return this.$http.post(
      this.APP_CONFIG.apiBaseUrl + 'integration/test',
      integrationTestMessage
    );
  }
}

angular
  .module('flowingly.services')
  .service('workflowApiService', WorkflowApiService);

export type WorkflowApiServiceType = InstanceType<typeof WorkflowApiService>;
